export const submenuInit = () => {
    const elm = document.querySelector('.main-nav__link');
    const subm = document.querySelector('.submenu');

    const showSubmenu = () => {
        subm.classList.add('submenu_active');
    };

    const hideSubmenu = () => {
        subm.classList.remove('submenu_active');
    };

    elm.addEventListener('mouseenter', showSubmenu);

    elm.addEventListener('mouseleave', ({ pageX, pageY }) => {
        const { width, x, y } = subm.getBoundingClientRect();

        let xB = pageX;
        let yB = pageY;
        const xA = x;
        const yA = y;
        const xC = x + width;
        const yC = y;

        const rotateVector = ({ pageX, pageY }) => {
            const xO = pageX;
            const yO = pageY;

            var p = (xA - xO) * (yB - yA) - (xB - xA) * (yA - yO);
            var q = (xB - xO) * (yC - yB) - (xC - xB) * (yB - yO);
            var r = (xC - xO) * (yA - yC) - (xA - xC) * (yC - yO);

            if ((p <= 0 && q <= 0 && r <= 0) || (p >= 0 && q >= 0 && r >= 0)) {
                xB = xO;
                yB = yO;
            } else {
                hideSubmenu();
                removeMouseMoveHandler();
            }
        };

        function removeMouseMoveHandler() {
            document.removeEventListener('mousemove', rotateVector);
            subm.removeEventListener('mouseenter', removeMouseMoveHandler);
        };

        document.addEventListener('mousemove', rotateVector);
        subm.addEventListener('mouseenter', removeMouseMoveHandler);
    });

    subm.addEventListener('mouseleave', () => {
        hideSubmenu();
    });
}