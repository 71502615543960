import {submenuInit} from "./submenu";
import mainNav from "./main-nav";
import { initModa } from "./modal";
import { formInit } from "./form";

submenuInit();
mainNav();

initModa('modal', 'btn-header');
initModa('modal', 'btn-promo');
initModa('modal', 'btn-about');

formInit('SeoOpen');
formInit('SeOpen');
